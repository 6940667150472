import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/app/components/primitives/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/dashboard/components/layout/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserTeamSwitcher"] */ "/app/dashboard/components/navigation/header/private/user-team-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderLinks"] */ "/app/dashboard/components/navigation/header/public/header-links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderPublic"] */ "/app/dashboard/components/navigation/header/public/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/dashboard/components/navigation/header/shared/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderShell"] */ "/app/dashboard/components/navigation/header/shared/header-shell.tsx");
;
import(/* webpackMode: "eager" */ "/app/dashboard/components/navigation/header/shared/new-service-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/dashboard/components/navigation/header/shared/user-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/dashboard/components/navigation/header/shared/website-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@jengaicons/react/dist/esm/icons/Outpostlogo.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
